// import {
//   Button,
//   useDisclosure,
//   Drawer,
//   DrawerBody,
//   DrawerHeader,
//   DrawerOverlay,
//   DrawerContent,
//   Input,
//   HStack,
//   VStack,
//   Center,
//   Text,
// } from "@chakra-ui/react";

export default function Search() {
  // const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      {/* <Button
        display={{
          base: "block",
          md: "none",
        }}
        variant={"ghost"}
        onClick={onOpen}
      >
        <i className="fa-regular fa-magnifying-glass"></i>
      </Button>
      <Drawer
        autoFocus={false}
        size={"full"}
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader
            paddingRight={4}
            borderBottomWidth={"thin"}
            borderColor={"gray.500"}
            paddingY={2}
          >
            <HStack>
              <Input
                paddingX={0}
                border={"none"}
                placeholder="搜尋"
                outline={"none"}
                _focus={{
                  boxShadow: "none",
                }}
              />
              <Button variant={"ghost"} onClick={onClose}>
                <i className="fa-regular fa-xmark"></i>
              </Button>
            </HStack>
          </DrawerHeader>
          <DrawerBody className="noScrollbar" paddingX={0}>
            <VStack>
              {new Array(12).fill(0).map((_, index) => {
                return (
                  <HStack
                    cursor={"pointer"}
                    width={"full"}
                    key={index}
                    onClick={onClose}
                    paddingX={4}
                    paddingY={2}
                  >
                    <Center width={8}>
                      <i className="fa-regular fa-magnifying-glass"></i>
                    </Center>
                    <Text>搜尋結果</Text>
                  </HStack>
                );
              })}
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer> */}
    </>
  );
}
