export function register() {
  if ("serviceWorker" in navigator) {
    // 检测浏览器是否支持 Service Worker
    window.addEventListener("load", () => {
      // 在窗口加载完毕后注册 Service Worker
      const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;

      navigator.serviceWorker
        .register(swUrl)
        .then((registration) => {
          console.log("Service Worker 注册成功:", registration);
        })
        .catch((error) => {
          console.log("Service Worker 注册失败:", error);
        });
    });
  }
}
