import { doc, runTransaction } from "firebase/firestore";
import { useCallback } from "react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { useFirebase } from "@context/firebase.context";
dayjs.extend(utc);

export const useLikeWork = () => {
  const { firestore } = useFirebase();
  return useCallback(
    async (userId, workId) => {
      if (!userId || !workId) return;
      try {
        await runTransaction(firestore, async (transaction) => {
          transaction.set(doc(firestore, `works/${workId}/likers/${userId}`), {
            createdAt: dayjs().utc().unix(),
          });
          transaction.set(
            doc(firestore, `users/${userId}/liked-works/${workId}`),
            {
              createdAt: dayjs().utc().unix(),
            },
          );
        });
      } catch (error) {}
    },
    [firestore],
  );
};
