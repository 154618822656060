import { Center, Text } from "@chakra-ui/react";
import HStack from "@components/stack/hstack";
import { Link } from "react-router-dom";

export default function SidebarItem({
  onClick = () => {},
  title = "",
  icon = <></>,
  isActive = false,
  to = "#",
}) {
  return (
    <Link to={to} style={{ width: "100%" }}>
      <HStack
        fontSize={"sm"}
        width={"full"}
        cursor={"pointer"}
        onClick={onClick}
        transition={"ease"}
        transitionDuration={".1s"}
        borderRadius={"md"}
        paddingX={2}
        _hover={{
          backgroundColor: isActive ? "orange.500" : "orange.800",
          color: isActive ? "gray.800" : "white",
        }}
        backgroundColor={isActive ? "orange.400" : "transparent"}
        color={isActive ? "gray.800" : "white"}
        fontWeight={isActive ? "bold" : "regular"}
        userSelect={"none"}
      >
        <Center width={12} height={12}>
          {icon}
        </Center>
        <Text>{title}</Text>
      </HStack>
    </Link>
  );
}
