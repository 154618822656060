import { Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";

const ForgetPasswordPage = lazy(() =>
  import("@pages/auth/forget-password.page"),
);
const LoginPage = lazy(() => import("@pages/auth/login.page"));
const RegisterPage = lazy(() => import("@pages/auth/register.page"));

export default function AuthRoutes() {
  return (
    <Routes>
      <Route
        path="login"
        element={
          <Suspense>
            <LoginPage />
          </Suspense>
        }
      ></Route>
      <Route
        path="register"
        element={
          <Suspense>
            <RegisterPage />
          </Suspense>
        }
      ></Route>
      <Route
        path="forget-password"
        element={
          <Suspense>
            <ForgetPasswordPage />
          </Suspense>
        }
      ></Route>
    </Routes>
  );
}
