import { Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";
const DepositCallbackPage = lazy(() => import("@pages/callback/deposit"));
const FirebaseCallbackPage = lazy(() => import("@pages/callback/firebase"));

export default function CallbackRoutes() {
  return (
    <Routes>
      <Route
        path="firebase"
        element={
          <Suspense>
            <FirebaseCallbackPage />
          </Suspense>
        }
      ></Route>
      <Route
        path="deposit"
        element={
          <Suspense>
            <DepositCallbackPage />
          </Suspense>
        }
      ></Route>
    </Routes>
  );
}
