import {
  Box,
  VStack,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react";
import Navbar from "@components/navbar";
import Sidebar from "@components/sidebar";
import { Outlet } from "react-router-dom";
import { useState } from "react";

export default function MainLayout({ children }) {
  const {
    isOpen: isSidebarOpen,
    onOpen: onSidebarOpen,
    onClose: onSidebarClose,
  } = useDisclosure();

  const [touchStart, setTouchStart] = useState(0);

  const onTouchStart = (event) => {
    setTouchStart(event.touches[0].clientX);
  };
  const onTouchEnd = (event) => {
    if (event.changedTouches[0].clientX - touchStart >= 60) onSidebarOpen();
  };
  const showSidebar = useBreakpointValue({
    base: false,
    lg: true,
  });
  return (
    <>
      {/* <BottomBarLayout> */}
      <Navbar
        isSidebarOpen={isSidebarOpen}
        onSidebarClose={onSidebarClose}
        onSidebarOpen={onSidebarOpen}
      />
      {showSidebar && (
        <VStack
          id="sidebar"
          overflowY={"auto"}
          width={{
            md: 64,
          }}
          height={"calc(100vh - 4rem)"}
          position={"fixed"}
          left={0}
          top={16}
          display={{
            base: "none",
            lg: "flex",
          }}
        >
          <Sidebar />
        </VStack>
      )}
      <Box
        onTouchStart={onTouchStart}
        onTouchEnd={onTouchEnd}
        width={"full"}
        paddingTop={16}
        paddingLeft={{
          base: 0,
          lg: 64,
        }}
      >
        {children}
        <Outlet />
      </Box>
      {/* </BottomBarLayout> */}
    </>
  );
}
