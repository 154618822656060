import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { initializeApp } from "firebase/app";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import {
  connectFirestoreEmulator,
  doc,
  getDoc,
  getFirestore,
  onSnapshot,
} from "firebase/firestore";
import { connectStorageEmulator, getStorage } from "firebase/storage";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";
import { getAnalytics } from "firebase/analytics";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import _ from "lodash-es";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

const FirebaseContext = createContext();
export const FirebaseProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isAuthLoading, setIsAuthLoading] = useState(true);
  const [userInfo, setUserInfo] = useState(null);
  const [usdExchangeRate, setUsdExchangeRate] = useState(30);
  const { app, auth, firestore, storage, functions, analytics } =
    useMemo(() => {
      const app = initializeApp({
        apiKey:
          process.env.REACT_APP_EMULATOR_ENABLE === "true"
            ? "test-api-key"
            : process.env.REACT_APP_FIREBASE_API_KEY,
        authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
        projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
        storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
        messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
        appId: process.env.REACT_APP_FIREBASE_APP_ID,
        measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
      });
      initializeAppCheck(app, {
        provider: new ReCaptchaV3Provider(
          "6LcsnyQpAAAAAKywt7XiZ32FnH-ExiUu99zUZz9h",
        ),
        isTokenAutoRefreshEnabled: true,
      });
      const auth = getAuth(app);
      const storage = getStorage(app);
      const db = getFirestore(app);
      const functions = getFunctions(app, "asia-east1");
      const analytics = getAnalytics(app);
      if (process.env.REACT_APP_EMULATOR_ENABLE === "true") {
        connectAuthEmulator(auth, "http://127.0.0.1:9099");
        connectFirestoreEmulator(db, "127.0.0.1", 8080);
        connectStorageEmulator(storage, "127.0.0.1", 9199);
        connectFunctionsEmulator(functions, "127.0.0.1", 5001);
      }

      return { app, auth, firestore: db, storage, functions, analytics };
    }, []);

  useMemo(() => {
    auth.onAuthStateChanged((user) => {
      setUser(user);
      setIsAuthLoading(false);
      if (user) {
        const docRef = doc(firestore, `users/${user.uid}`);
        getDoc(docRef).then((docSnap) => {
          setUserInfo(docSnap.data());
        });
        const unsubscribe = onSnapshot(docRef, (doc) => {
          setUserInfo(doc.data());
        });
        return () => {
          unsubscribe();
        };
      } else {
        setUserInfo(null);
      }
    });
  }, [auth, firestore]);

  const loadExchangeRate = useCallback(async () => {
    const docRef = doc(firestore, `app/exchange-rates`);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists) {
      setUsdExchangeRate(_.get(docSnap.data(), "data.USD", 0.033));
    }
  }, [firestore]);

  useEffect(() => {
    loadExchangeRate();
  }, [loadExchangeRate]);

  const value = {
    user,
    userInfo,
    app,
    auth,
    isAuthLoading,
    usdExchangeRate,
    firestore,
    storage,
    functions,
    analytics,
  };
  return (
    <>
      <FirebaseContext.Provider value={value}>
        {children}
      </FirebaseContext.Provider>
    </>
  );
};

export function useFirebase() {
  return useContext(FirebaseContext);
}
