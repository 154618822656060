import { Box } from "@chakra-ui/react";

export default function MainContainer({ children }) {
  return (
    <Box
      width={"full"}
      maxWidth={{
        base: 160,
        sm: 200,
        md: 180,
        xl: 200,
        "2xl": 280,
      }}
      marginX={"auto"}
    >
      {children}
    </Box>
  );
}
