import { useCallback } from "react";
import _ from "lodash-es";

export const useGetWorkUrl = () => {
  return useCallback((work) => {
    if (!work) return null;
    switch (_.get(work, "type", null)) {
      case "gallery":
      default:
        return `/galleries/${_.get(work, "id")}`;
      case "video":
        return `/videos/${_.get(work, "id")}`;
    }
  }, []);
};
