import { Box, useDisclosure } from "@chakra-ui/react";
import AdminNavbar from "@components/navbar/admin-navbar";
import VStack from "@components/stack/vstack";
import { Outlet } from "react-router-dom";
import { useState } from "react";
import AdminSidebar from "@components/sidebar/admin-sidebar";
import MustAdminLayout from "@layouts/must-admin.layout";

export default function AdminLayout({ children }) {
  const {
    isOpen: isSidebarOpen,
    onOpen: onSidebarOpen,
    onClose: onSidebarClose,
  } = useDisclosure();

  const [touchStart, setTouchStart] = useState(0);

  const onTouchStart = (event) => {
    setTouchStart(event.touches[0].clientX);
  };
  const onTouchEnd = (event) => {
    if (event.changedTouches[0].clientX - touchStart >= 60) onSidebarOpen();
  };

  return (
    <>
      <MustAdminLayout>
        <AdminNavbar
          isSidebarOpen={isSidebarOpen}
          onSidebarClose={onSidebarClose}
          onSidebarOpen={onSidebarOpen}
        />
        <VStack
          id="sidebar"
          overflowY={"auto"}
          width={{
            md: 64,
          }}
          height={"calc(100vh - 4rem)"}
          position={"fixed"}
          left={0}
          top={16}
          display={{
            base: "none",
            lg: "flex",
          }}
        >
          <AdminSidebar />
        </VStack>
        <Box
          minHeight={"100vh"}
          onTouchStart={onTouchStart}
          onTouchEnd={onTouchEnd}
          width={"full"}
          paddingTop={16}
          paddingLeft={{
            base: 0,
            lg: 64,
          }}
        >
          {children}
          <Outlet />
        </Box>
      </MustAdminLayout>
    </>
  );
}
