import { Box, HStack, Text, useBreakpointValue } from "@chakra-ui/react";
import { useMatch } from "react-router-dom";
import SidebarItem from "@components/sidebar/item";
import { useFirebase } from "@context/firebase.context";
import VStack from "@components/stack/vstack";
import { useTranslation } from "react-i18next";
import { Suspense, lazy } from "react";

const SidebarBrandList = lazy(() => import("@components/sidebar/brand/list"));
const ChangeLanguageItem = lazy(() =>
  import("@components/sidebar/change-language"),
);

export default function Sidebar({ onTouchStart, onTouchEnd }) {
  const { user, isAuthLoading } = useFirebase();
  const { t } = useTranslation();
  const isIndex = useMatch({ path: "/" });
  const isPersonal = useMatch({ path: "/personal" });
  const isAuthLogin = useMatch({ path: "/auth/login" });
  const isLikedWorks = useMatch({ path: "/liked-works" });
  const isPurchasedWorks = useMatch({ path: "/purchased-works" });
  const isHistories = useMatch({ path: "/histories" });
  const isDeposit = useMatch({ path: "/deposit" });

  const inDrawer = useBreakpointValue({
    base: true,
    lg: false,
  });
  // display={{
  //   base: "none",
  //   lg: "flex",
  // }}

  return (
    <>
      <VStack width={"full"}>
        <VStack
          width={"full"}
          paddingX={4}
          paddingY={4}
          borderBottomWidth={"1px"}
          borderColor={"gray.700"}
        >
          <SidebarItem
            to="/"
            title={t("components.sidebar.home")}
            icon={<i className="fa-regular fa-house"></i>}
            isActive={isIndex}
          />
        </VStack>
        <VStack
          width={"full"}
          paddingX={4}
          paddingY={4}
          borderBottomWidth={"1px"}
          borderColor={"gray.700"}
        >
          <HStack
            width={"full"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Text paddingX={4} fontSize={"lg"} fontWeight={"bold"}>
              {t("components.sidebar.my_pages")}
            </Text>
          </HStack>
          <Box marginTop={4} width={"full"}>
            {inDrawer && (
              <SidebarItem
                to="/personal"
                title={t("components.sidebar.personal")}
                icon={<i className="fa-regular fa-user"></i>}
                isActive={isPersonal}
              />
            )}
            <SidebarItem
              to="/deposit"
              title={t("components.sidebar.deposit")}
              icon={<i className="fa-regular fa-money-simple-from-bracket"></i>}
              isActive={isDeposit}
            />
            {!isAuthLoading && !user && (
              <SidebarItem
                to="/auth/login"
                title={t("components.sidebar.login")}
                icon={<i className="fa-regular fa-right-to-bracket"></i>}
                isActive={isAuthLogin}
              />
            )}
            {!isAuthLoading && user && (
              <>
                <SidebarItem
                  to="/histories"
                  title={t("components.sidebar.watch_histories")}
                  isActive={isHistories}
                  icon={<i className="fa-regular fa-clock-rotate-left"></i>}
                />
                <SidebarItem
                  to="/liked-works"
                  title={t("components.sidebar.liked_works")}
                  isActive={isLikedWorks}
                  icon={<i className="fa-regular fa-heart"></i>}
                />
                <SidebarItem
                  to="/purchased-works"
                  title={t("components.sidebar.purchased_works")}
                  isActive={isPurchasedWorks}
                  icon={<i className="fa-regular fa-bag-shopping"></i>}
                />
              </>
            )}
          </Box>
        </VStack>
        <VStack
          width={"full"}
          paddingX={4}
          paddingY={4}
          alignItems={"start"}
          borderBottomWidth={"1px"}
          borderColor={"gray.700"}
        >
          <HStack
            width={"full"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Text paddingX={4} fontSize={"lg"} fontWeight={"bold"}>
              {t("components.sidebar.brands")}
            </Text>
          </HStack>
          <Box marginTop={4} width={"full"}>
            <Suspense>
              <SidebarBrandList />
            </Suspense>
          </Box>
        </VStack>
        <VStack
          width={"full"}
          paddingX={4}
          paddingY={4}
          borderBottomWidth={"1px"}
          borderColor={"gray.700"}
        >
          <Suspense>
            <ChangeLanguageItem />
          </Suspense>
          <SidebarItem
            to="/faq"
            title={t("components.sidebar.faq")}
            isActive={useMatch({ path: "/faq" })}
            icon={<i className="fa-regular fa-circle-question"></i>}
          />
          <SidebarItem
            to="/privacy-policies"
            title={t("components.sidebar.privacy_policies")}
            isActive={useMatch({ path: "/privacy-policies" })}
            icon={<i className="fa-regular fa-blinds-open"></i>}
          />
          <SidebarItem
            to="/terms-of-use"
            title={t("components.sidebar.terms_of_use")}
            isActive={useMatch({ path: "/terms-of-use" })}
            icon={<i className="fa-regular fa-scale-balanced"></i>}
          />
          <SidebarItem
            to="/inquiry"
            title={t("components.sidebar.inquiry")}
            isActive={useMatch({ path: "/inquiry" })}
            icon={<i className="fa-regular fa-message-arrow-up-right"></i>}
          />
        </VStack>
      </VStack>
    </>
  );
}
