import AdminLayout from "@layouts/admin.layout";
import { Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";
const AdminIndexPage = lazy(() => import("@pages/admin/index"));
const AdminWorksPage = lazy(() => import("@pages/admin/works/index"));
const AdminGalleryCreatePage = lazy(() =>
  import("@pages/admin/works/create-gallery"),
);
const AdminVideoCreatePage = lazy(() =>
  import("@pages/admin/works/create-video"),
);
const AdminUsersPage = lazy(() => import("@pages/admin/users/index"));
const AdminUserInfoPage = lazy(() => import("@pages/admin/users/info"));
const AdminCastsPage = lazy(() => import("@pages/admin/casts/index"));
const AdminBrandsPage = lazy(() => import("@pages/admin/brands/index"));
const AdminBrandCreatePage = lazy(() => import("@pages/admin/brands/create"));
const AdminDepositsPage = lazy(() => import("@pages/admin/deposits/index"));

export default function AdminRoutes() {
  return (
    <Routes>
      <Route path="/" element={<AdminLayout />}>
        <Route
          index
          element={
            <Suspense>
              <AdminIndexPage />
            </Suspense>
          }
        ></Route>
        <Route
          path="users"
          element={
            <Suspense>
              <AdminUsersPage />
            </Suspense>
          }
        ></Route>
        <Route
          path="users/:id/:section"
          element={
            <Suspense>
              <AdminUserInfoPage />
            </Suspense>
          }
        ></Route>
        <Route
          path="brands"
          element={
            <Suspense>
              <AdminBrandsPage />
            </Suspense>
          }
        ></Route>
        <Route
          path="brands/create"
          element={
            <Suspense>
              <AdminBrandCreatePage />
            </Suspense>
          }
        ></Route>
        <Route
          path="works"
          element={
            <Suspense>
              <AdminWorksPage />
            </Suspense>
          }
        ></Route>
        <Route
          path="galleries/create"
          element={
            <Suspense>
              <AdminGalleryCreatePage />
            </Suspense>
          }
        ></Route>
        <Route
          path="videos/create"
          element={
            <Suspense>
              <AdminVideoCreatePage />
            </Suspense>
          }
        ></Route>
        <Route
          path="casts"
          element={
            <Suspense>
              <AdminCastsPage />
            </Suspense>
          }
        ></Route>
        <Route
          path="deposits"
          element={
            <Suspense>
              <AdminDepositsPage />
            </Suspense>
          }
        ></Route>
      </Route>
    </Routes>
  );
}
