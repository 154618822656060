import { Box } from "@chakra-ui/react";

export default function BodyContainer({ children, ...props }) {
  return (
    <Box
      width={"full"}
      paddingX={{
        base: 4,
        md: 0,
      }}
      {...props}
    >
      {children}
    </Box>
  );
}
