import { useEffect } from "react";
import { useFirebase } from "@context/firebase.context";
import _ from "lodash";
export default function MustAdminLayout({ children }) {
  const { isAuthLoading, user } = useFirebase();
  useEffect(() => {
    if (!isAuthLoading && !user && !_.get(user, "roles.admin", false))
      window.location.replace("/");
  }, [isAuthLoading, user]);

  return <>{children}</>;
}
