import { useCallback } from "react";
import path from "path-browserify";
import _ from "lodash-es";

export const useGetPublicThumbnail = () => {
  return useCallback((filePath, variant = 320) => {
    if (_.isEmpty(filePath)) return "";
    const fileId = path.parse(filePath).name;
    const filename = `${path.dirname(
      filePath,
    )}/thumbnails/${variant}/${fileId}.webp`;
    return `https://firebasestorage.googleapis.com/v0/b/${
      process.env.REACT_APP_FIREBASE_STORAGE_BUCKET
    }/o/${encodeURIComponent(filename)}?alt=media`;
  }, []);
};
