import { doc, getDoc } from "firebase/firestore";
import { useCallback } from "react";
import _ from "lodash";
import { useExpandBrand } from "@hooks/expand-brand";
import { useFirebase } from "@context/firebase.context";

export const useExpandWork = () => {
  const { firestore } = useFirebase();
  const expandBrand = useExpandBrand();
  return useCallback(
    async (work) => {
      const brand = _.get(work, "brand", null);
      if (brand) {
        const docRef = doc(firestore, `brands/${brand}`);
        const docSnap = await getDoc(docRef);
        work.brand = await expandBrand(docSnap.data());
        work.brand.id = docSnap.id;
      }
      return work;
    },
    [firestore, expandBrand],
  );
};
