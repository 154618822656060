import {
  Circle,
  Button,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Input,
  useToast,
  useBreakpointValue,
} from "@chakra-ui/react";
import _ from "lodash-es";
import HStack from "@components/stack/hstack";
import VStack from "@components/stack/vstack";
import { useMemo } from "react";
import { useApp } from "@context/app.context";
import { Trans, useTranslation } from "react-i18next";

export default function WorkShare({ isOpen, onOpen, onClose, work }) {
  const { t } = useTranslation();
  const toast = useToast();
  const type = useBreakpointValue({
    base: "drawer",
    md: "modal",
  });
  const { toastPosition } = useApp();

  const shareableLink = useMemo(() => {
    const workId = _.get(work, "id", null);
    return workId
      ? `${window.location.protocol}//${window.location.host}${window.location.pathname}`
      : "";
  }, [work]);

  const onShareFacebook = () => {
    if (shareableLink)
      window.open(
        `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
          shareableLink,
        )}`,
        "facebook-share-dialog",
        "width=800,height=600",
      );
  };

  const onShareTwitter = () => {
    if (shareableLink)
      window.open(
        `https://twitter.com/intent/tweet?url=${encodeURIComponent(
          shareableLink,
        )}`,
        "twitter-share-dialog",
        "width=800,height=600",
      );
  };

  const onShareLine = () => {
    if (shareableLink)
      window.open(
        `https://social-plugins.line.me/lineit/share?url=${encodeURIComponent(
          shareableLink,
        )}`,
        "line-share-dialog",
        "width=800,height=600",
      );
  };

  const onShareTelegram = () => {
    if (shareableLink)
      window.open(
        `https://t.me/share/url?url=${encodeURIComponent(shareableLink)}`,
        "telegram-share-dialog",
        "width=800,height=600",
      );
  };

  const onCopyLink = () => {
    navigator.clipboard.writeText(shareableLink);
    toast({
      title: t("components.work_share.toast.link_copied"),
      duration: 2000,
      status: "success",
      position: toastPosition,
    });
  };

  return (
    <>
      {type === "modal" && (
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader textAlign={"center"}>
              <Trans i18nKey={"components.work_share.title"} />
            </ModalHeader>
            <ModalBody>
              <VStack width={"full"} alignItems={"start"}>
                <HStack overflowX={"auto"} className="noScroll">
                  <Circle
                    onClick={onShareFacebook}
                    cursor={"pointer"}
                    size={16}
                    backgroundColor={"#1877f2"}
                    color={"white"}
                    fontSize={"2xl"}
                    marginRight={4}
                  >
                    <i className="fa-brands fa-facebook"></i>
                  </Circle>
                  <Circle
                    onClick={onShareTwitter}
                    cursor={"pointer"}
                    size={16}
                    backgroundColor={"#1da1f2"}
                    color={"white"}
                    fontSize={"2xl"}
                    marginRight={4}
                  >
                    <i className="fa-brands fa-twitter"></i>
                  </Circle>

                  <Circle
                    onClick={onShareLine}
                    cursor={"pointer"}
                    size={16}
                    backgroundColor={"#06c755"}
                    color={"white"}
                    fontSize={"2xl"}
                    marginRight={4}
                  >
                    <i className="fa-brands fa-line"></i>
                  </Circle>
                  <Circle
                    onClick={onShareTelegram}
                    cursor={"pointer"}
                    size={16}
                    backgroundColor={"#0088cc"}
                    color={"white"}
                    fontSize={"2xl"}
                    marginRight={4}
                  >
                    <i className="fa-brands fa-telegram"></i>
                  </Circle>
                </HStack>
              </VStack>
            </ModalBody>

            <ModalFooter paddingRight={4}>
              <HStack width={"full"}>
                <Input
                  disabled={true}
                  readOnly={true}
                  width={"full"}
                  marginRight={2}
                  value={_.get(work, "id", null) ? shareableLink : ""}
                />
                <Button
                  minWidth={"fit-content"}
                  colorScheme="orange"
                  onClick={onCopyLink}
                >
                  <Trans i18nKey={"components.work_share.copy"} />
                </Button>
                <Button
                  minWidth={"fit-content"}
                  marginLeft={2}
                  variant={"ghost"}
                  onClick={onClose}
                >
                  <Trans i18nKey={"components.work_share.close"} />
                </Button>
              </HStack>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
      {type === "drawer" && (
        <Drawer isOpen={isOpen} placement="bottom" onClose={onClose}>
          <DrawerOverlay />
          <DrawerContent>
            <DrawerHeader textAlign={"center"}>
              <Trans i18nKey={"components.work_share.title"} />
            </DrawerHeader>

            <DrawerBody>
              <VStack width={"full"} alignItems={"start"}>
                <HStack overflowX={"auto"} className="noScroll">
                  <Circle
                    onClick={onShareFacebook}
                    cursor={"pointer"}
                    size={16}
                    backgroundColor={"#1877f2"}
                    color={"white"}
                    fontSize={"2xl"}
                    marginRight={4}
                  >
                    <i className="fa-brands fa-facebook"></i>
                  </Circle>
                  <Circle
                    onClick={onShareTwitter}
                    cursor={"pointer"}
                    size={16}
                    backgroundColor={"#1da1f2"}
                    color={"white"}
                    fontSize={"2xl"}
                    marginRight={4}
                  >
                    <i className="fa-brands fa-twitter"></i>
                  </Circle>
                  <Circle
                    onClick={onShareLine}
                    cursor={"pointer"}
                    size={16}
                    backgroundColor={"#06c755"}
                    color={"white"}
                    fontSize={"2xl"}
                    marginRight={4}
                  >
                    <i className="fa-brands fa-line"></i>
                  </Circle>
                  <Circle
                    onClick={onShareTelegram}
                    cursor={"pointer"}
                    size={16}
                    backgroundColor={"#0088cc"}
                    color={"white"}
                    fontSize={"2xl"}
                    marginRight={4}
                  >
                    <i className="fa-brands fa-telegram"></i>
                  </Circle>
                </HStack>
              </VStack>
            </DrawerBody>

            <DrawerFooter paddingRight={4}>
              <HStack width={"full"}>
                <Input
                  disabled={true}
                  readOnly={true}
                  width={"full"}
                  onClick={onCopyLink}
                  marginRight={2}
                  defaultValue={_.get(work, "id", null) ? shareableLink : ""}
                />
                <Button
                  minWidth={"fit-content"}
                  colorScheme="orange"
                  onClick={onCopyLink}
                >
                  <Trans i18nKey={"components.work_share.copy"} />
                </Button>
                <Button
                  minWidth={"fit-content"}
                  marginLeft={2}
                  variant="ghost"
                  onClick={onClose}
                >
                  <Trans i18nKey={"components.work_share.close"} />
                </Button>
              </HStack>
            </DrawerFooter>
          </DrawerContent>
        </Drawer>
      )}
    </>
  );
}
