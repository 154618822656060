import { doc, runTransaction } from "firebase/firestore";
import { useCallback } from "react";
import { useFirebase } from "@context/firebase.context";

export const useUnlikeWork = () => {
  const { firestore } = useFirebase();
  return useCallback(
    async (userId, workId) => {
      try {
        await runTransaction(firestore, async (transaction) => {
          // 删除第一个文档
          transaction.delete(
            doc(firestore, `works/${workId}/likers/${userId}`),
          );

          // 删除第二个文档
          transaction.delete(
            doc(firestore, `users/${userId}/liked-works/${workId}`),
          );
        });
      } catch (error) {}
    },
    [firestore],
  );
};
