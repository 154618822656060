import {
  AspectRatio,
  Badge,
  Box,
  Button,
  Circle,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Skeleton,
  SkeletonCircle,
  SkeletonText,
  Square,
  Text,
  useBreakpointValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { useApp } from "@context/app.context";
import { useTranslation } from "react-i18next";
import _ from "lodash-es";
import HStack from "@components/stack/hstack";
import VStack from "@components/stack/vstack";
import WorkShare from "@components/work-share";
import { useFirebase } from "@context/firebase.context";
import { useEffect, useState } from "react";
import { useLikeWork } from "@hooks/like-work";
import { useUnlikeWork } from "@hooks/unlike-work";
import { useHashPurchasedWork } from "@hooks/has-purchased-work";
import { useHasLikedWork } from "@hooks/has-liked-work";
import dayjs from "dayjs";
import { useGetPublicThumbnail } from "@hooks/get-public-thumbnail";
import { useGetWorkUrl } from "@hooks/get-work-url";

export default function GalleryProductCard({
  work = null,
  isLoading = false,
  lazyLoadImage = false,
}) {
  const { getInfo, toastPosition, formatMoment } = useApp();
  const { i18n, t } = useTranslation();
  const { user } = useFirebase();
  const hasLikedWork = useHasLikedWork();
  const hasPurchasedWork = useHashPurchasedWork();
  const unlikeWork = useUnlikeWork();
  const likeWork = useLikeWork();
  const [isLiked, setIsLiked] = useState(false);
  const [isPurchased, setIsPurchased] = useState(null);
  const toast = useToast();
  const {
    isOpen: isOptionOpen,
    onOpen: onOptionOpen,
    onClose: onOptionClose,
  } = useDisclosure();
  const {
    isOpen: isShareOpen,
    onOpen: onShareOpen,
    onClose: onShareClose,
  } = useDisclosure();
  const optionMode = useBreakpointValue({
    base: "drawer",
    md: "menu",
  });
  const getWorkUrl = useGetWorkUrl();

  useEffect(() => {
    hasLikedWork(_.get(user, "uid", null), _.get(work, "id", null)).then(
      setIsLiked,
    );
    hasPurchasedWork(_.get(user, "uid", null), _.get(work, "id", null)).then(
      setIsPurchased,
    );
  }, [user, work, hasLikedWork, hasPurchasedWork]);

  const onLikeButtonClick = async () => {
    if (user && work) {
      await likeWork(user.uid, work.id);
      setIsLiked(await hasLikedWork(user.uid, work.id));
      toast({
        title: t("components.video_card.toast.like_successfully"),
        duration: 2000,
        status: "success",
        position: toastPosition,
      });
    } else if (!user) {
      toast({
        title: t("components.video_card.toast.no_login"),
        duration: 2000,
        status: "warning",
        position: toastPosition,
      });
    }
  };

  const onCancelLikeButtonClick = async () => {
    if (user && work) {
      await unlikeWork(user.uid, work.id);
      setIsLiked(await hasLikedWork(user.uid, work.id));
      toast({
        title: t("components.video_card.toast.cancel_like_successfully"),
        duration: 2000,
        status: "error",
        position: toastPosition,
      });
    } else if (!user) {
      toast({
        title: t("components.video_card.toast.no_login"),
        duration: 2000,
        status: "warning",
        position: toastPosition,
      });
    }
  };

  const getPublicThumbnail = useGetPublicThumbnail();
  if (isLoading) {
    return (
      <Box cursor={"pointer"}>
        <HStack paddingY={4} paddingX={2} paddingBottom={2}>
          <SkeletonCircle size={6}></SkeletonCircle>
          <Skeleton width={12} height={4} marginLeft={2} />
        </HStack>
        <AspectRatio ratio={16 / 9}>
          <Skeleton />
        </AspectRatio>
        <VStack width={"full"} alignItems={"start"} paddingY={2} paddingX={4}>
          <SkeletonText noOfLines={2} skeletonHeight={4} width={"full"} />
        </VStack>
      </Box>
    );
  }
  return (
    <>
      <Box cursor={"pointer"}>
        <HStack justifyContent={"space-between"} paddingX={2} paddingBottom={2}>
          <Box>
            {_.get(work, "brand", null) && (
              <Link to={`/brands/${_.get(work, "brand.id", "")}`}>
                <HStack>
                  <Circle
                    size={6}
                    backgroundColor={"black"}
                    borderRadius={"full"}
                    borderWidth={2}
                    borderColor={"orange.600"}
                    overflow={"hidden"}
                  >
                    <Image
                      src={getPublicThumbnail(
                        _.get(work, "brand.logo.path", null),
                        64,
                      )}
                      loading={lazyLoadImage ? "lazy" : "eager"}
                      alt={getInfo(
                        _.get(work, "brand.info", {}),
                        "title",
                        i18n.language,
                      )}
                    ></Image>
                  </Circle>
                  <Text marginLeft={2} fontSize={"sm"} fontWeight={"bold"}>
                    {getInfo(
                      _.get(work, "brand.info", {}),
                      "title",
                      i18n.language,
                    )}
                  </Text>
                </HStack>
              </Link>
            )}
          </Box>
          {optionMode === "menu" && (
            <Menu>
              <MenuButton
                marginRight={-4}
                as={Button}
                variant={"ghost"}
                borderRadius={"full"}
                aria-label="More Option"
              >
                <i className="fa-regular fa-ellipsis-vertical"></i>
              </MenuButton>
              <MenuList>
                {!isLiked && (
                  <MenuItem onClick={onLikeButtonClick}>
                    <HStack justifyContent={"center"}>
                      <Square size={6}>
                        <i className="fa-regular fa-heart"></i>
                      </Square>
                      <Text ml={2} fontSize={"sm"}>
                        {t("components.video_card.like")}
                      </Text>
                    </HStack>
                  </MenuItem>
                )}
                {isLiked && (
                  <MenuItem onClick={onCancelLikeButtonClick}>
                    <HStack justifyContent={"center"} color={"pink.400"}>
                      <Square size={6}>
                        <i className="fa-solid fa-heart"></i>
                      </Square>
                      <Text ml={2} fontSize={"sm"}>
                        {t("components.video_card.liked")}
                      </Text>
                    </HStack>
                  </MenuItem>
                )}
                <MenuItem onClick={onShareOpen}>
                  <HStack justifyContent={"center"}>
                    <Square size={6}>
                      <i className="fa-regular fa-share"></i>
                    </Square>
                    <Text ml={2} fontSize={"sm"}>
                      {t("components.video_card.share")}
                    </Text>
                  </HStack>
                </MenuItem>
              </MenuList>
            </Menu>
          )}
          {optionMode === "drawer" && (
            <Button
              marginRight={-4}
              variant={"ghost"}
              borderRadius={"full"}
              onClick={onOptionOpen}
              aria-label="More Option"
            >
              <i className="fa-regular fa-ellipsis-vertical"></i>
            </Button>
          )}
        </HStack>
        <Link to={getWorkUrl(work)}>
          <AspectRatio ratio={16 / 9}>
            <Box
              backgroundColor={"black"}
              position={"relative"}
              overflow={"hidden"}
              borderRadius={"lg"}
            >
              <Box
                position={"absolute"}
                left={0}
                top={0}
                width={"full"}
                height={"full"}
              >
                {_.get(work, "type") === "gallery" && (
                  <Image
                    width={"full"}
                    src={getPublicThumbnail(
                      _.get(work, "covers[0].path", null),
                      320,
                    )}
                    alt={getInfo(
                      _.get(work, "info", {}),
                      "title",
                      i18n.language,
                    )}
                  ></Image>
                )}
              </Box>
              <HStack position={"absolute"} right={0} bottom={0} padding={2}>
                <Badge backgroundColor={"blackAlpha.700"} variant={"solid"}>
                  {`${work.contents.length} P`}
                </Badge>
              </HStack>
            </Box>
          </AspectRatio>
        </Link>
        <HStack width={"full"} alignItems={"start"} paddingX={2}>
          <Link
            style={{ width: "100%" }}
            to={_.get(work, "id", null) && `/galleries/${work.id}`}
          >
            <HStack
              width={"full"}
              justifyContent={"space-between"}
              alignItems={"start"}
              paddingTop={2}
            >
              <VStack alignItems={"start"} width={"full"}>
                <HStack
                  width={"full"}
                  justifyContent={"space-between"}
                  alignItems={"start"}
                >
                  <Text fontSize={"md"} fontWeight={"semibold"} noOfLines={2}>
                    {getInfo(_.get(work, "info", {}), "title", i18n.language)}
                  </Text>
                  {isPurchased ? (
                    <HStack
                      fontSize={"sm"}
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      <Text as={"span"} fontSize={"sm"} color={"green.300"}>
                        <i className="fa-thin fa-check"></i>
                      </Text>
                      <Text
                        ml={2}
                        as={"span"}
                        fontWeight={"bold"}
                        color={"green.300"}
                      >
                        {t("components.video_card.purchased")}
                      </Text>
                    </HStack>
                  ) : (
                    <HStack
                      fontSize={"sm"}
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      <Text as={"span"} fontSize={"sm"} color={"orange.300"}>
                        <i className="fa-thin fa-coin-front"></i>
                      </Text>
                      <Text
                        ml={2}
                        as={"span"}
                        fontWeight={"bold"}
                        color={"orange.300"}
                      >
                        {_.get(work, "points", 0)}
                      </Text>
                    </HStack>
                  )}
                </HStack>
                <Text marginTop={2} fontSize={"sm"} color={"gray.400"}>
                  {formatMoment(
                    dayjs.unix(_.get(work, "createdAt")),
                    "MMMM DD, YYYY",
                  )}
                  {/* {_.get(work, 'createdAt')} */}
                </Text>
              </VStack>
            </HStack>
          </Link>
        </HStack>
      </Box>

      <Drawer isOpen={isOptionOpen} placement="bottom" onClose={onOptionClose}>
        <DrawerOverlay backgroundColor={`rgba(0,0,0,0.8)`} />
        <DrawerContent>
          <DrawerHeader textAlign={"center"} noOfLines={1}>
            {getInfo(_.get(work, "info", {}), "title", i18n.language)}
            {/* <i className="fa-regular fa-ellipsis"></i> */}
          </DrawerHeader>
          <DrawerBody paddingX={4}>
            <Link to={_.get(work, "id", null) && `/galleries/${work.id}`}>
              <Box>
                <HStack alignItems={"start"}>
                  <Box width={32} minWidth={32}>
                    <AspectRatio ratio={16 / 9}>
                      <Image
                        src={getPublicThumbnail(
                          _.get(work, "covers[0].path", null),
                          320,
                        )}
                        loading={lazyLoadImage ? "lazy" : "eager"}
                        alt={getInfo(
                          _.get(work, "info", {}),
                          "title",
                          i18n.language,
                        )}
                      ></Image>
                    </AspectRatio>
                  </Box>
                  <VStack alignItems={"start"} marginLeft={4}>
                    <Text fontSize={"xs"} noOfLines={4}>
                      {getInfo(
                        _.get(work, "info", {}),
                        "description",
                        i18n.language,
                      )}
                    </Text>
                  </VStack>
                </HStack>
              </Box>
            </Link>
          </DrawerBody>
          <DrawerFooter paddingX={4}>
            {isLiked ? (
              <Button
                onClick={onCancelLikeButtonClick}
                color="white"
                cursor={"pointer"}
                colorScheme="pink"
              >
                <Text>
                  <i className="fa-solid fa-heart"></i>
                </Text>
                <Text marginLeft={2}> {t("components.video_card.liked")}</Text>
              </Button>
            ) : (
              <Button
                onClick={onLikeButtonClick}
                color="white"
                cursor={"pointer"}
              >
                <Text>
                  <i className="fa-regular fa-heart"></i>
                </Text>
                <Text marginLeft={2}> {t("components.video_card.like")}</Text>
              </Button>
            )}
            <Button
              marginLeft={2}
              onClick={onShareOpen}
              color="white"
              cursor={"pointer"}
            >
              <Text>
                <i className="fa-regular fa-share"></i>
              </Text>
              <Text marginLeft={2}> {t("components.video_card.share")}</Text>
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
      <WorkShare isOpen={isShareOpen} onClose={onShareClose} work={work} />
    </>
  );
}
