import { useCallback } from "react";
import { useFirebase } from "@context/firebase.context";
import { doc, getDoc } from "firebase/firestore";

export const useHasLikedWork = () => {
  const { firestore } = useFirebase();
  return useCallback(
    async (userId, workId) => {
      const docRef = await getDoc(
        doc(firestore, `users/${userId}/liked-works/${workId}`),
      );
      return docRef.exists();
    },
    [firestore],
  );
};
