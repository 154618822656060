import { useCallback } from "react";
import _ from "lodash";
import { useAppDB } from "@hooks/app-db";
import { usePublicDownloadURL } from "@hooks/public-download-url";

export const useExpandBrand = () => {
  const publicDownloadURL = usePublicDownloadURL();
  const db = useAppDB();
  return useCallback(
    async (brand) => {
      const id = _.get(brand, "id", null);
      const cachedBrandData = await db.brands.get(id);
      if (cachedBrandData) return cachedBrandData;
      const brandCoverPath = _.get(brand, "cover.path", null);
      if (brandCoverPath) {
        brand.cover.url = publicDownloadURL(brandCoverPath);
      }

      const brandLogoPath = _.get(brand, "logo.path", null);
      if (brandLogoPath) {
        brand.logo.url = publicDownloadURL(brandLogoPath);
      }
      await db.brands.put(brand);
      return brand;
    },
    [db, publicDownloadURL],
  );
};
