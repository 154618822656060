// import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useFirebase } from "@context/firebase.context";
import { collection, getDocs, limit, orderBy, query } from "firebase/firestore";
import { useMemo } from "react";
import VideoList from "@components/video-list";
import MainContainer from "@components/container/main";
import BodyContainer from "@components/container/body";
import { useCallback } from "react";
import { useExpandWork } from "@hooks/expand-work";
import { HStack, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

function Index() {
  const { t } = useTranslation();
  const { firestore } = useFirebase();
  const expandWork = useExpandWork();
  const q = useMemo(() => {
    return query(
      collection(firestore, "works"),
      orderBy("createdAt", "desc"),
      limit(24),
    );
  }, [firestore]);

  const loadWorks = useCallback(async () => {
    const works = await new Promise((resolve) => {
      getDocs(q).then((querySnapshot) => {
        let results = [];
        querySnapshot.forEach((doc) => {
          results.push(doc.data());
        });
        resolve(results);
      });
    });
    const expandWorks = await Promise.all(
      works.map((work) => {
        return expandWork(work);
      }),
    );
    return expandWorks;
  }, [q, expandWork]);

  return (
    <>
      <Helmet>
        <title>LustaHouse</title>
      </Helmet>
      <MainContainer>
        <BodyContainer marginBottom={4}>
          <HStack
            width={"full"}
            marginY={4}
            justifyContent={{
              base: "center",
              md: "start",
            }}
          >
            <Text
              fontSize={"2xl"}
              fontWeight={"bold"}
              marginX={{
                base: 4,
                md: 0,
              }}
            >
              {t(`pages.index.recommended_works`)}
            </Text>
          </HStack>
          <VideoList loadWorks={loadWorks} />
        </BodyContainer>
      </MainContainer>
    </>
  );
}

export default Index;
