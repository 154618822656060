import { HStack } from "@chakra-ui/react";
import { forwardRef, useImperativeHandle } from "react";

export default forwardRef(({ children, ...props }, componentRef) => {
  useImperativeHandle(componentRef, () => ({}));
  return (
    <HStack {...props} gap={0}>
      {children}
    </HStack>
  );
});
