import {
  Badge,
  Button,
  Center,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import HStack from "@components/stack/hstack";
import { useLocation, useNavigate } from "react-router-dom";
import AdminSidebar from "@components/sidebar/admin-sidebar";
import { useEffect } from "react";

export default function AdminNavbar({
  isSidebarOpen,
  onSidebarClose = () => {},
  onSidebarOpen = () => {},
}) {
  const location = useLocation();
  const navigate = useNavigate();
  const sidebarEnabled = useBreakpointValue({
    base: true,
    lg: false,
  });

  useEffect(() => {
    onSidebarClose();
  }, [location, onSidebarClose]);

  return (
    <>
      <HStack
        backgroundColor={"gray.900"}
        zIndex={50}
        position={"fixed"}
        width={"full"}
        height={16}
        paddingRight={{
          base: 4,
          md: 8,
        }}
        paddingLeft={{
          base: 4,
          md: 6,
        }}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <HStack>
          <Button
            variant={"ghost"}
            onClick={() => sidebarEnabled && onSidebarOpen()}
            aria-label="Toggle Navbar"
          >
            <i className="fa-regular fa-bars"></i>
          </Button>
          <Center
            onClick={() => navigate("/admin")}
            fontSize={"xl"}
            className="logo"
            cursor={"pointer"}
            marginLeft={2}
            userSelect={false}
          >
            <Text as="span" color={"orange.500"}>
              LH
            </Text>
            <Badge colorScheme="orange" size={"xs"} marginLeft={2}>
              Admin
            </Badge>
          </Center>
        </HStack>
        <HStack></HStack>
      </HStack>
      <Drawer
        isOpen={isSidebarOpen}
        onClose={onSidebarClose}
        placement={"left"}
      >
        <DrawerOverlay />
        <DrawerContent maxWidth={64}>
          <DrawerHeader height={16}>
            <HStack>
              <Button variant={"ghost"} onClick={onSidebarClose}>
                <i className="fa-regular fa-bars"></i>
              </Button>
              <Center
                fontSize={"xl"}
                className="logo"
                userSelect={"none"}
                marginLeft={2}
              >
                <Text as="span" color={"orange.500"}>
                  LH
                </Text>
                <Badge colorScheme="orange" size={"xs"} marginLeft={2}>
                  Admin
                </Badge>
              </Center>
            </HStack>
          </DrawerHeader>

          <DrawerBody id="sidebar-drawer" paddingX={0}>
            <AdminSidebar />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}
