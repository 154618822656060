import { Grid, GridItem } from "@chakra-ui/react";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import GalleryProductCard from "@components/card/gallery-product-card";
import VideoProductCard from "@components/card/video-product-card";
import _ from "lodash";

export default forwardRef(({ loadWorks = async () => [] }, componentRef) => {
  const reload = () => {
    setIsLoading(true);
    loadWorks().then((results) => {
      setIsLoading(false);
      setWorks(results);
    });
  };
  useImperativeHandle(componentRef, () => ({
    reload,
  }));
  const [works, setWorks] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setIsLoading(true);
    loadWorks().then((results) => {
      setIsLoading(false);
      setWorks(results);
    });
  }, [loadWorks]);

  return (
    <Grid templateColumns="repeat(12, 1fr)" gap={4} width={"full"}>
      {isLoading
        ? new Array(24).fill(0).map((_, index) => {
            return (
              <GridItem
                key={index}
                width="100%"
                colSpan={{
                  base: 12,
                  sm: 6,
                  xl: 4,
                  "2xl": 4,
                }}
              >
                <GalleryProductCard isLoading={true} />
              </GridItem>
            );
          })
        : works.map((work, index) => {
            return (
              <GridItem
                key={work.id}
                width="100%"
                colSpan={{
                  base: 12,
                  sm: 6,
                  xl: 4,
                  "2xl": 4,
                }}
              >
                {_.get(work, "type", null) === "gallery" && (
                  <GalleryProductCard work={work} />
                )}
                {_.get(work, "type", null) === "video" && (
                  <VideoProductCard work={work} />
                )}
              </GridItem>
            );
          })}
    </Grid>
  );
});
