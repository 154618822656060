import Dexie from "dexie";
import { useMemo } from "react";

export const useAppDB = () => {
  return useMemo(() => {
    const db = new Dexie("app");
    db.version(1).stores({
      casts: "id",
      works: "id",
      brands: "id",
    });
    return db;
  }, []);
};
